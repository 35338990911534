<template>
    <div :style="{ '--badge-height': size }">
        <span @click.capture="handleClick('android')">
            <a :href="androidLink">
                <img v-if="outlined" src="../assets/google-badge-color.svg" :alt="$t('appStoreLinks.android')" class="outlined-badge">
                <img v-else src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" :alt="$t('appStoreLinks.android')">
            </a>
        </span>

        <br v-if="stacked">
        <span v-else style="display: inline-block; width: 1ch;" />

        <span @click.capture="handleClick('ios')">
            <a :href="iosLink">
                <img v-if="outlined" src="../assets/apple-badge-color.svg" :alt="$t('appStoreLinks.ios')" class="outlined-badge">
                <img v-else src="https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg" :alt="$t('appStoreLinks.ios')" class="ios-badge">
            </a>
        </span>
    </div>
</template>

<script lang="ts">
import Vue from '@/vueTyped';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/config';
import { trackAppLinkClick } from '@/tracking';

export default Vue.extend({
    props: {
        size: {
            type: String,
            default: '40px'
        },

        outlined: {
            type: Boolean,
            default: false,
        },

        stacked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            androidLink: ANDROID_APP_LINK,
            iosLink: IOS_APP_LINK,
        };
    },

    methods: {
        handleClick(platform: string) {
            this.$emit('click-link', platform);
            trackAppLinkClick(platform);
        },
    },
});
</script>

<style lang="postcss" scoped>
img {
    height: var(--badge-height);
}
</style>
