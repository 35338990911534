<template>
    <cms-content v-slot="{ document }" type="site_content" uid="home-content" class="page" :class="{ small: $vuetify.breakpoint.xs }" data-test-id="Home page">
        <section class="side-by-side">
            <div class="side-by-side-content" style="padding-block: 40px;">
                <prismic-rich-text v-if="document" :field="document.data.body[0].primary.content" class="hero-content" :class="{ 'not-large': $vuetify.breakpoint.mdAndDown }" />

                <auth-button
                    v-if="!currentUser"
                    large
                    color="white"
                    :block="$vuetify.breakpoint.xs"
                    class="mt-4"
                    :class="{ 'mr-4': !$vuetify.breakpoint.xs }"
                >
                    {{ $t('signUpOrIn') }}
                </auth-button>

                <base-button
                    v-else
                    :to="{ name: RouteNames.POSTS }"
                    large
                    color="white"
                    :block="$vuetify.breakpoint.xs"
                    class="mt-4"
                    :class="{ 'mr-4': !$vuetify.breakpoint.xs }"
                >
                    {{ $t('actions.viewposts') }}
                </base-button>

                <base-button
                    href="https://partners.iseechange.com/"
                    large
                    color="primary"
                    target="partners"
                    :block="$vuetify.breakpoint.xs"
                    class="mt-4"
                >
                    {{ $t('nav.partnerWithUs') }}
                </base-button>

                <app-download-links outlined class="app-links mt-8" />
            </div>

            <div class="side-by-side-graphic">
                <img src="/home-page/hero.webp" :alt="$t('heroAlt')" class="hero-graphic">
            </div>
        </section>

        <section class="recent-posts">
            <a
                v-if="recentPosts.length !== 0 && specialLink"
                :href="specialLink.href"
                target="special-home-page-link"
                class="recent-post-link special-link"
                :style="{ '--photo': `url('${specialLink.image}')` }"
            >
                <div class="recent-post-heading">
                    {{ specialLink.heading }}
                </div>

                <div class="recent-post-snippet">
                    {{ specialLink.title }}
                </div>

                <div class="recent-post-byline">
                    {{ specialLink.subtitle }}
                </div>
            </a>

            <router-link
                v-for="post of recentPosts"
                :key="post.id"
                :to="{ name: RouteNames.POST_DETAIL, params: { id: post.id } }"
                class="recent-post-link"
                :style="{ '--photo': `url('${$options.filters.formatImage(post.photoObjs[0], 600)}')` }"
            >
                <div class="recent-post-heading">
                    {{ $t('nav.latestsighting') }}
                </div>

                <div class="recent-post-snippet">
                    {{ post.textBody.split(' ').length > 20 ? `${post.textBody.split(' ').slice(0, 20).join(' ')}...` : post.textBody }}
                </div>

                <div class="recent-post-byline">
                    {{ post.observedAt | formatDate('MMMM D') }}
                    &nbsp;|&nbsp;
                    {{ post.userObj.firstName }} {{ currentUser ? post.userObj.lastName : post.userObj.lastName.slice(0, 1) }}
                    &nbsp;|&nbsp;
                    {{ post.shortAddress }}
                </div>
            </router-link>
        </section>

        <div class="tracker-block">
            <section class="side-by-side">
                <div class="side-by-side-content">
                    <div class="inline-stack">
                        <heat-tracker-logo class="mr-4" />
                        <flood-tracker-logo />
                    </div>

                    <h2 class="mt-8">{{ $t('trackerHeading') }}</h2>
                    <p>{{ $t('trackerBody') }}</p>

                    <div class="inline-stack mt-10">
                        <base-button
                            to="/tracker/heat/impact"
                            large
                            outlined
                            color="var(--color-red-fanta)"
                            :block="$vuetify.breakpoint.xs"
                        >
                            {{ $t('ctaHeat') }}
                        </base-button>

                        <base-button
                            to="/tracker/flooding/floodDepth"
                            large
                            outlined
                            color="var(--color-blue-ocean)"
                            :block="$vuetify.breakpoint.xs"
                        >
                            {{ $t('ctaFlooding') }}
                        </base-button>
                    </div>
                </div>

                <div class="side-by-side-graphic">
                    <img src="/home-page/tracker-screens.webp" :alt="$t('trackerAlt')" class="tracker-graphic">
                </div>
            </section>
        </div>

        <section class="community">
            <div v-if="document" class="community-content px-4 text-center">
                <prismic-rich-text v-if="document" :field="document.data.body[1].primary.content" />

                <div class="community-partners my-4">
                    <template v-for="(item, i) in document.data.body[2].items">
                        <prismic-rich-text :key="i" :field="item.content" />
                    </template>
                </div>

                <base-button href="https://partners.iseechange.com/" large color="primary">{{ $t('home.community.linktext') }}</base-button>
            </div>
        </section>

        <how-it-works-partial class="how-it-works" />
    </cms-content>
</template>

<script lang="ts">
import AppDownloadLinks from '@/components/AppDownloadLinks.vue';
import AuthButton from '@/components/AuthButton.vue';
import CmsContent from '@/components/CmsContent.vue';
import HowItWorksPartial from '@/components/HowItWorksPartial.vue';
import FloodTrackerLogo from '@/components/logo/FloodTracker.vue';
import HeatTrackerLogo from '@/components/logo/HeatTracker.vue';
import RouteNames from '@/router/names';
import { CurrentUser, Post } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        AppDownloadLinks,
        AuthButton,
        CmsContent,
        FloodTrackerLogo,
        HeatTrackerLogo,
        HowItWorksPartial,
    },

    i18n: {
        messages: {
            en: {
                signUpOrIn: 'Sign up or log in',
                heroAlt: 'Photos of weather situations with overlayed data points',
                trackerHeading: 'Quickly report about extreme heat or flooding, no app required!',
                trackerBody: 'Our Tracker tools empower communities to respond to extreme weather.',
                ctaHeat: 'Report Heat',
                ctaFlooding: 'Report Flooding',
                trackerAlt: 'Screens showing the HeatTracker and FloodTracker UIs',
            },

            es: {
                signUpOrIn: 'Regístrese o inicie sesión',
                heroAlt: 'Fotos de situaciones meteorológicas con puntos de datos superpuestos',
                trackerHeading: 'Informe rápidamente sobre calor extremo o inundaciones, ¡no se requiere ninguna aplicación!',
                trackerBody: 'Nuestras herramientas de rastreador capacitan a las comunidades para responder al clima extremo.',
                ctaHeat: 'Informar calor',
                ctaFlooding: 'Informar inundaciones',
                trackerAlt: 'Pantallas que muestran el HeatTracker y UIS de inundación',
            },
        },
    },

    computed: {
        RouteNames(): typeof RouteNames {
            return RouteNames;
        },

        currentUser(): CurrentUser | null {
                return this.$store.state.account.currentUser;
        },

        specialLink() {
            return {
                image: 'https://images.prismic.io/iseechange/Zq0ZeUaF0TcGIqEH_valeriia-neganova-5lTaDDeG9WU-unsplash.jpg?auto=format,compress&height=640',
                href: 'https://www.local10.com/news/local/2024/06/03/new-app-helps-miami-residents-report-flooding-issues-during-hurricane-season-and-beyond/',
                heading: 'Press coverage',
                title: 'Miami’s WPLG: “App is helping residents report flooding issues during hurricane season and beyond”',
                subtitle: 'Read more →',
            };
        },

        recentPosts(): Post[] {
            return this.$store.state.posts.mostRecent
                .filter(post => post.media.find(media => media.mediaType === 'image'))
                .slice(0, this.specialLink ? 2 : 3);
        },

        recentStoryImage(): string {
            // @ts-ignore
            const extDotIndex = this.recentStory.image.lastIndexOf('.');
            // @ts-ignore
            const path = this.recentStory.image.slice(0, extDotIndex);
            // @ts-ignore
            const ext = this.recentStory.image.slice(extDotIndex + 1);
            return `${path}-800x425.${ext}`;
        }
    },

    async mounted() {
        this.$store.dispatch('fetchMostRecentPosts');
        this.$store.dispatch('fetchStories');
    },
});
</script>

<style lang="postcss" scoped>
.page {
    --space: 48px;
    background: var(--color-secondary);
    color: var(--color-secondary-contrast);
    overflow: hidden;
}

.small {
    --space: 24px;
}

.side-by-side {
    align-items: center;
    display: flex;
    gap: calc(var(--space) / 2);
    justify-content: space-between;
    margin: auto;
    max-width: calc(100% - var(--space) * 2);
    width: 90rem;
}

.small .side-by-side {
    flex-direction: column;
}

.side-by-side-content {
    flex: 1 0;
    margin-block-start: calc(var(--space) * 2);
}

.side-by-side-content:not(.small *) {
    margin-block-end: calc(var(--space) * 2);
}

.inline-stack {
    display: flex;
    gap: 16px;
}

.small .inline-stack {
    flex-direction: column;
}

.hero-content {
    font-size: var(--type-lead);
    max-width: 50rem;
}

.hero-content :deep(h3) {
    font-size: var(--type-title);
}

.hero-content.not-large :deep(h3) {
    font-size: var(--type-subtitle);
}

.hero-content :deep(br) {
    display: none;
}

.small .app-links {
    text-align: center;
}

.side-by-side-graphic {
    flex: 1 5;
}

.side-by-side-graphic img {
    display: block;
    margin-inline: auto;
}

.hero-graphic {
    aspect-ratio: 672 / 482;
    max-width: 100%;
    width: 672px;
}

.small .hero-graphic {
    min-width: 504px;
}

.recent-posts {
    display: flex;
}

.small .recent-posts {
    flex-direction: column;
}

.recent-post-link {
    background:
        linear-gradient(#0009, #0006),
        var(--photo) center/cover;
    color: var(--color-white);
    flex: 1;
    line-height: 1.2;
    padding: var(--space);
    text-decoration: none;
    text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.2);
}

.recent-post-link:not(.small *),
.special-link {
    aspect-ratio: 10 / 9;
}

.small .recent-post-link {
    border-top: 1px solid #fff6;
}

.recent-post-heading {
    color: var(--color-warning-tint);
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: bold;
}

.recent-post-snippet {
    font-size: 2em;
    margin-block-start: 0.3em;
}

.recent-post-byline {
    font-size: 0.9em;
    font-weight: bold;
    margin-block-start: 0.8em;
    text-transform: uppercase;
}

.tracker-block {
    background: linear-gradient(265deg, #FF0BBB, #0172DA);
}

.tracker-block .side-by-side-graphic {
    align-self: flex-end;
}

.tracker-graphic {
    aspect-ratio: 457 / 422;
    max-width: 100%;
    width: 457px;
}

.tracker-graphic:not(.small *) {
    margin-block-start: var(--space);
}

.community {
    background-image: url('/img/partner-photo.jpg');
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
}

.community-content {
    background-color: var(--color-white);
    color: var(--color-black);
    padding-block: calc(var(--space) * 2);
}

.community-content :deep(h3) {
    font-size: var(--type-subtitle);
}

.community-content :deep(p) {
    margin: 0 auto;
    max-width: 30em;
}

@media screen and (min-width: 768px) {
    .community-content {
        width: 66%;
    }
}

.community-partners {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.community-partners :deep(img) {
    max-width: 100px;
    margin: 0 1rem;
}

.how-it-works {
    color: var(--color-black);
}
</style>
