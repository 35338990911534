<template>
    <button v-if="fauxLink" type="button" class="faux-link-button" v-bind="$attrs" v-on="$listeners">
        <slot />
    </button>

    <v-btn
        v-else
        rounded
        :xxcolor="pressed ? 'var(--pressed-button-color)' : $attrs.color"
        :elevation="$attrs.elevation || 0"
        :aria-pressed="pressed"
        :active-class="neverActive ? 'never-active' : undefined"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <v-icon
                v-if="slotName === 'default' && prependIcon"
                :key="`${slotName}-prepend-icon`"
                :size="$attrs.small ? 12 : 16"
                class="mr-2"
            >
                {{ prependIcon }}
            </v-icon>
            <slot :name="slotName" />
        </template>
    </v-btn>
</template>
<script lang="ts">
import Vue from '@/vueTyped';
export default Vue.extend({
    props: {
        fixedAction: {
            type: Boolean,
            default: false,
        },
        prependIcon: {
            type: String,
            default: '',
        },
        neverActive: {
            type: Boolean,
            default: false,
        },
        pressed: {
            type: Boolean,
            default: false,
        },
        fauxLink: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
<style lang="postcss" scoped>
.faux-link-button {
    color: var(--color-primary);
    font: inherit;
    text-decoration: underline;
}

.v-btn {
    --pressed-button-color: var(--color-dark);
    letter-spacing: inherit;
    text-align: center;
    text-transform: inherit;
}

.v-btn.v-btn--outlined:not(.theme--dark) {
    background: white;
    border: 1px solid #D0D5D9;
}

/* Large buttons get special white space and wrapping? */
.v-btn.v-size--large {
    font-size: var(--type-base);
    height: auto;
    padding: 0.85em var(--spacing-8);
    white-space: normal;
}

.v-btn.v-size--large :deep(.v-btn__content) {
    flex-shrink: 1;
}

.v-btn[aria-pressed] {
    background: var(--pressed-button-color) !important;
    border-color: transparent !important;
    color: var(--color-white) !important;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #e0e0e0 !important;
}

/* Enable never showing as active.
https://github.com/vuetifyjs/vuetify/issues/8172#issuecomment-738304152 */
.v-btn--active.never-active:not(:focus):not(:hover)::before {
    opacity: 0 !important;
}
</style>
