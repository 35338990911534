<template>
  <section class="how-it-works">
    <component :is="headingTag" class="t-subtitle text-center">{{ $t('home.howitworks.headline') }}</component>

    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="4" md="3" class="text-center">
        <div class="mt-6 mb-3" aria-hidden="true">{{ $t('home.howitworks.col1') }}</div>
        <img src="../assets/images/weather.png" :alt="$t('home.howitworks.col1')">
      </v-col>

      <v-col cols="12" sm="4" md="3" class="text-center">
        <div class="mt-6 mb-3" aria-hidden="true">{{ $t('home.howitworks.col2') }}</div>
        <img src="../assets/images/phone.png" :alt="$t('home.howitworks.col2')">
      </v-col>

      <v-col cols="12" sm="4" md="3" class="text-center">
        <div class="mt-6 mb-3" aria-hidden="true">{{ $t('home.howitworks.col3') }}</div>
        <img src="../assets/images/globe.png" :alt="$t('home.howitworks.col3')">
      </v-col>
    </v-row>

    <v-row no-gutters="">
      <v-col>
        <p class="text-center mt-10 pt-6">
          <a href="/getting-started">Learn More</a>
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script lang="ts">
export default {
  props: {
    headingTag: {
      type: String,
      default: 'h2',
    }
  }
}
</script>

<style lang="postcss" scoped>
  .how-it-works {
    background-color: var(--color-light-tint);
    padding: 64px 0;
  }

  .how-it-works img {
    max-height: 120px;
  }
</style>
